import React, { HTMLAttributes } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { FluidObject } from "gatsby-image";
import ZoomableImage from "../shared/ZoomableImage";
export { projectListImage } from "../Projects";

export const CollageWrapper = styled.div`
  column-count: 1;
  column-gap: 5px;

  @media screen and (min-width: 400px) {
    column-count: 2;
  }

  @media screen and (min-width: 800px) {
    column-count: 3;
  }

  @media screen and (min-width: 1200px) {
    column-count: 4;
  }

  @media screen and (min-width: 1800px) {
    column-count: 5;
  }

  & > div {
    break-inside: avoid;
    margin-bottom: 5px;
  }

  img {
    width: 100%;
    height: auto;
  }
`;

interface FileQueryResult {
  images: {
    nodes: {
      relativePath: string;
      childImageSharp: {
        fluid: FluidObject;
      };
    }[];
  };

  descriptions: {
    nodes: {
      relativePath: string;
      childJson?: {
        description?: string;
      };
    }[];
  };
}

export enum CollageType {
  Illustration = "illustration",
  Ink = "ink",
  Figurative = "figurative",
  AnimalDugnad = "animal-dugnad",
  InternetTriptych = "internet-triptych",
  Bio = "bio",
  PixelArt = "pixel-art",
  ConceptArt = "concept-art",
}

export function Collage({
  type,
  ...props
}: { type: CollageType } & HTMLAttributes<HTMLDivElement>) {
  const files = useStaticQuery<FileQueryResult>(
    graphql`
      query {
        images: allFile(
          filter: { relativePath: { glob: "collages/**/*.(jpg|png)" } }
          sort: { fields: relativePath }
        ) {
          nodes {
            relativePath
            ...ProjectListImage
          }
        }

        descriptions: allFile(
          filter: { relativePath: { glob: "collages/**/*.json" } }
        ) {
          nodes {
            relativePath
            childJson {
              description
            }
          }
        }
      }
    `
  );
  const imageResults = files.images.nodes
    .filter(x => x.relativePath.startsWith(`collages/${type}`))
    .filter(x => /\.(jpg|png)$/.test(x.relativePath))
    .map(x => {
      const description = files.descriptions.nodes.find(
        y => x.relativePath.replace(/\.(jpg|png)$/, ".json") === y.relativePath
      )?.childJson?.description;
      return { ...x, description };
    });

  return (
    <CollageWrapper {...props}>
      {imageResults.map(x => (
        <div key={x.childImageSharp.fluid.src}>
          <ZoomableImage
            fluid={x.childImageSharp.fluid}
            description={x.description}
          />
        </div>
      ))}
    </CollageWrapper>
  );
}
