import styled from "styled-components";
import { onDesktop } from "../../shared/layout/Breakpoints";

const Banner = styled.div`
  margin-bottom: 2em;

  ${onDesktop`
    padding-top: 1em;
  `}
`;

export default Banner;
