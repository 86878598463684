import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import GatsbyImage from "gatsby-image";
import styled from "styled-components";

const GalleryList = styled.ul`
  list-style-type: none;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
`;

const GalleryItemLi = styled.li`
  padding: 0;
  width: 50%;
  padding: 0.5em;

  @media screen and (min-width: 720px) {
    width: 25%;
    padding: 1em;
  }
`;

const TriptychGalleryItemLi = styled(GalleryItemLi)`
  width: 33%;

  @media screen and (min-width: 720px) {
    width: 33%;
  }
`;

const GalleryItem = ({ page, children }) => {
  return (
    <GalleryItemLi>
      {page ? <Link to={page}>{children}</Link> : children}
    </GalleryItemLi>
  );
};

const TriptychGalleryItem = ({ page, children }) => {
  return (
    <TriptychGalleryItemLi>
      {page ? <Link to={page}>{children}</Link> : children}
    </TriptychGalleryItemLi>
  );
};

export const GalleryImageElement = styled(GatsbyImage)`
  display: block;
  width: 100%;
  height: auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
`;

const GalleryImageElementCrisp = styled(GalleryImageElement)`
  image-rendering: crisp-edges;
  image-rendering: pixelated;
`;

const GalleryImg = ({ crisp, ...props }) => {
  const Img = crisp ? GalleryImageElementCrisp : GalleryImageElement;

  function onClick(event) {
    event.preventDefault();
  }

  return <Img onClick={onClick} {...props} />;
};

const TriptychGalleryImg = ({ crisp, ...props }) => {
  const Img = crisp ? GalleryImageElementCrisp : GalleryImageElement;

  function onClick(event) {
    event.preventDefault();
  }

  return <Img onClick={onClick} {...props} />;
};

const pages = {
  octopus: "/projects/triptych/",
};

const ProjectSection = styled.section`
  margin-bottom: 2em;
`;

export const projectListImage = graphql`
  fragment ProjectListImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

const TriptychCollection = ({ title, imageEntries, crisp, page }) => {
  const titleContent = <h3>{title}</h3>;

  return (
    <ProjectSection key={title}>
      {page ? <Link to={page}>{titleContent}</Link> : titleContent}
      <GalleryList>
        {imageEntries.map(([key, value]) => {
          const { childImageSharp } = value;
          const { fluid } = childImageSharp;

          return (
            <TriptychGalleryItem key={key} page={pages[key]}>
              <TriptychGalleryImg fluid={fluid} crisp={crisp} />
            </TriptychGalleryItem>
          );
        })}
      </GalleryList>
    </ProjectSection>
  );
};

const Collection = ({ title, imageEntries, crisp, page }) => {
  const titleContent = <h3>{title}</h3>;
  return (
    <ProjectSection key={title}>
      {page ? <Link to={page}>{titleContent}</Link> : titleContent}
      <GalleryList>
        {imageEntries.map(([key, value]) => {
          const { extraProps, childImageSharp } = value;
          const { fluid } = childImageSharp;

          return (
            <GalleryItem key={key} page={pages[key]} {...extraProps}>
              <GalleryImg fluid={fluid} crisp={crisp} />
            </GalleryItem>
          );
        })}
      </GalleryList>
    </ProjectSection>
  );
};

const Projects = () => {
  const images = useStaticQuery(graphql`
    query {
      animals_chickens: file(
        relativePath: { eq: "work/animals-and-nature/chickens_A5.png" }
      ) {
        ...ProjectListImage
      }
      animals_coat: file(
        relativePath: { eq: "work/animals-and-nature/Coat_A5.png" }
      ) {
        ...ProjectListImage
      }
      animals_pumpkin: file(
        relativePath: { eq: "work/animals-and-nature/Pumpkin_A5.png" }
      ) {
        ...ProjectListImage
      }
      animals_seashells: file(
        relativePath: { eq: "work/animals-and-nature/seashells_A5.png" }
      ) {
        ...ProjectListImage
      }
      animals_skull: file(
        relativePath: { eq: "work/animals-and-nature/skull_A5.png" }
      ) {
        ...ProjectListImage
      }
      animals_turtle: file(
        relativePath: { eq: "work/animals-and-nature/Turtle_A6.png" }
      ) {
        ...ProjectListImage
      }
      animals_catsHolo: file(
        relativePath: { eq: "work/animals-and-nature/3-cats_A3_holo.png" }
      ) {
        ...ProjectListImage
      }
      animals_hedgehogColor: file(
        relativePath: { eq: "work/animals-and-nature/Hedgehog_color_A5.png" }
      ) {
        ...ProjectListImage
      }
      digital_bear: file(relativePath: { eq: "work/digital/A_bear.png" }) {
        ...ProjectListImage
      }
      digital_ffPoster: file(
        relativePath: { eq: "work/digital/FF_poster.png" }
      ) {
        ...ProjectListImage
      }
      digital_super: file(relativePath: { eq: "work/digital/super.png" }) {
        ...ProjectListImage
      }
      dreams_misty: file(
        relativePath: { eq: "work/dreams-collection/blue_misty_A4.png" }
      ) {
        ...ProjectListImage
      }
      dreams_poodle: file(
        relativePath: { eq: "work/dreams-collection/poodle_A4.png" }
      ) {
        ...ProjectListImage
      }
      dreams_plant: file(
        relativePath: { eq: "work/dreams-collection/Purple_plant_A5.png" }
      ) {
        ...ProjectListImage
      }
      japan_gate: file(relativePath: { eq: "work/japan/gate_A4.png" }) {
        ...ProjectListImage
      }
      triptych_part1: file(
        relativePath: { eq: "work/large-scale-projects/triptych/part1_A3.png" }
      ) {
        ...ProjectListImage
      }
      triptych_part2: file(
        relativePath: { eq: "work/large-scale-projects/triptych/part2_A3.png" }
      ) {
        ...ProjectListImage
      }
      triptych_part3: file(
        relativePath: { eq: "work/large-scale-projects/triptych/part3_A3.png" }
      ) {
        ...ProjectListImage
      }
      digital_dugnad: file(
        relativePath: { eq: "work/large-scale-projects/animal-dugnad@1920.png" }
      ) {
        ...ProjectListImage
      }
      norway_telemark: file(
        relativePath: { eq: "work/norway-collection/telemark_A4.png" }
      ) {
        ...ProjectListImage
      }
      norway_mountain: file(
        relativePath: { eq: "work/norway-collection/mountain_A4.png" }
      ) {
        ...ProjectListImage
      }
      pixel_alien: file(relativePath: { eq: "work/pixel-art/alien.png" }) {
        ...ProjectListImage
      }
      pixel_oso: file(
        relativePath: { eq: "work/pixel-art/oso_que_bebe_cerveza.png" }
      ) {
        ...ProjectListImage
      }
      pixel_cat: file(
        relativePath: { eq: "work/pixel-art/pixel_art_cat.png" }
      ) {
        ...ProjectListImage
      }
      pixel_nostalgic: file(
        relativePath: { eq: "work/pixel-art/nostalgic.png" }
      ) {
        ...ProjectListImage
      }
      rocks_rocks: file(
        relativePath: { eq: "work/rocks-collection/Rocks_A5.png" }
      ) {
        ...ProjectListImage
      }
      rocks_perissa: file(
        relativePath: { eq: "work/rocks-collection/Perissa_A5.png" }
      ) {
        ...ProjectListImage
      }
      scientific_exploding: file(
        relativePath: { eq: "work/scientific-illustrations/3-exploding.png" }
      ) {
        ...ProjectListImage
      }
      scientific_lumiblast: file(
        relativePath: {
          eq: "work/scientific-illustrations/Scientific_illustrtion_Lumiblast.png"
        }
      ) {
        ...ProjectListImage
      }
    }
  `);

  const ProjectCollection = ({ title, prefix, ...props }) => (
    <Collection
      title={title}
      imageEntries={Object.entries(images).filter(([key]) =>
        key.startsWith(`${prefix}_`)
      )}
      {...props}
    />
  );

  const TriptychProjectCollection = ({ title, prefix, ...props }) => (
    <TriptychCollection
      title={title}
      imageEntries={Object.entries(images).filter(([key]) =>
        key.startsWith(`${prefix}_`)
      )}
      {...props}
    />
  );

  return (
    <>
      <TriptychProjectCollection
        title="The Internet Triptych"
        prefix="triptych"
        page="projects/triptych"
      />
      <ProjectCollection title="Digital" prefix="digital" />
      <ProjectCollection title="Dreams Collection" prefix="dreams" />
      <ProjectCollection title="Japan" prefix="japan" />
      <ProjectCollection title="Norway Collection" prefix="norway" />
      <ProjectCollection title="Pixel Art" prefix="pixel" crisp />
      <ProjectCollection title="Rocks Collection" prefix="rocks" />
      <ProjectCollection title="Scientific Illustrations" prefix="scientific" />
      <ProjectCollection title="Animals and Nature" prefix="animals" />
    </>
  );
};

export default Projects;
