import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Collage, CollageType } from "../components/collages/Collage";
import TextContent from "../components/shared/TextContent";
import Quote from "../components/shared/Quote";
import Banner from "../components/shared/Banner";
import MailingListForm from "../components/social/MailingListForm";

const BioPage = () => {
  return (
    <Layout>
      <SEO title="Bio & Contact" />
      <Banner>
        <Collage type={CollageType.Bio} />
      </Banner>
      <TextContent>
        <h1>Bio & Contact</h1>
        <Quote>
          How is it to have a terrible nightmare, while dreaming peacefully, not
          scared of the consequences? Or how is it to experience a dream which
          is non threatening, yet has mysterious and dark undertones?
        </Quote>
        <MailingListForm />
        <p>
          I am interested in the combination of dreams and nightmares. My style
          is driven towards the middle between these opposites. I believe that
          dreams reflect reality, even if incompletely, and are not pure
          fantasy. As a biologist, I have been surrounded by noisy pictures and
          incomplete data. Reality is noisy, and one must navigate towards an
          understanding of it with care. In my drawings, noise has a meaning of
          incomplete reality, because sometimes what we see is not the complete
          story. It may be like a nightmare passing off as a sweet dream. I work
          with noisy and dramatic media, like dark charcoals and black inks,
          while I incorporate soft and colorful watercolor into my work, as a
          way to create a dreamy nightmare.
        </p>
        <p>
          I do conceptual drawings about various topics radiating from my
          interest in science and technology, and its impact on society. I look
          forward to illustrating articles about such topics.
        </p>
        <p>
          As a biologist with a background in neuroscience, cancer research and
          bioinformatics, I also am able to read specialized texts and to
          produce conceptual illustrations for both scientific and popular
          publications. Follow my newsletter for an insight on my process with a
          dash of science facts!
        </p>
        <p>
          On the side, I like to work making small video games. If you want to
          collaborate in a future Game Jam, please let me know. I will also
          update about new mini-games and their process in my newsletter, so
          stay tuned!
        </p>
        <p>
          <a href="mailto:post@inkplasma.com">post@inkplasma.com</a>
        </p>
      </TextContent>
    </Layout>
  );
};

export default BioPage;
