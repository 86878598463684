import React, {
  useState,
  useRef,
  useLayoutEffect,
  RefObject,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import GatsbyImage, {
  GatsbyImageProps,
  FluidObject,
  GatsbyImageFluidProps,
} from "gatsby-image";

const Background = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  transition: opacity 0.2s ease;
`;
const Body = styled.div`
  width: 100%;
  height: 100%;
`;
const Description = styled.div`
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.67);
  color: white;
  padding: 1em 2em;
  width: 100%;
  text-align: center;
  z-index: 1;
  font-size: 1.2em;
`;
const Zoomed = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;

  img {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    pointer-events: all;
    max-height: 100%;
    object-fit: contain;
    transform-origin: 50% 50%;
    transition: transform 0.2s ease;
    cursor: zoom-out;
    z-index: 1;
  }
`;

function getContainedSize(
  img: HTMLImageElement,
  hPos: number,
  vPos: number
): Rect {
  const cWidth = img.width,
    cHeight = img.height,
    width = img.naturalWidth,
    height = img.naturalHeight;
  const oRatio = width / height,
    cRatio = cWidth / cHeight;

  const size =
    oRatio > cRatio
      ? {
          width: cWidth,
          height: cWidth / oRatio,
        }
      : {
          width: cHeight * oRatio,
          height: cHeight,
        };

  const left = (cWidth - size.width) * hPos;
  const top = (cHeight - size.height) * vPos;

  return {
    ...size,
    left,
    top,
  };
}

function getContainedPosition(img: HTMLImageElement) {
  const pos = window
    .getComputedStyle(img)
    .getPropertyValue("object-position")
    .split(" ");
  const hPos = parseInt(pos[0]),
    vPos = parseInt(pos[1]);
  return getContainedSize(img, hPos / 100, vPos / 100);
}

type Rect = { left: number; top: number; width: number; height: number };

export interface ZoomableImageProps extends GatsbyImageFluidProps {
  description?: string;
}

export const ZoomableImage = styled(function({
  description,
  ...props
}: ZoomableImageProps) {
  const [isZoomed, setIsZoomed] = useState(false);
  const original = useRef<{ imageRef: RefObject<HTMLImageElement> } | null>(
    null
  );
  const preview = useRef<HTMLImageElement>(null);
  const container = useRef<HTMLDivElement>(null);
  const [previewRect, setPreviewRect] = useState<Rect | null>(null);

  function calculatePreviewRect() {
    const currentImg = preview.current;

    if (!currentImg) return;

    const x = getContainedPosition(currentImg);

    setPreviewRect(x);
  }

  const getTransform = useCallback(
    function() {
      const originalRect = original.current?.imageRef.current?.getBoundingClientRect();

      if (!previewRect || !originalRect) return undefined;

      const scaleX = originalRect.width / previewRect.width;
      const scaleY = originalRect.height / previewRect.height;

      const translateX =
        previewRect.left +
        previewRect.width / 2 -
        originalRect.left -
        originalRect.width / 2;
      const translateY =
        previewRect.top +
        previewRect.height / 2 -
        originalRect.top -
        originalRect.height / 2;

      return `translate(${-translateX}px, ${-translateY}px) scale(${scaleX}, ${scaleY}) `;
    },
    [previewRect]
  );

  const [transform, setTransform] = useState<string | undefined>(undefined);
  const [visibility, setDisplay] = useState<"visible" | "hidden" | undefined>(
    "hidden"
  );
  const [opacity, setOpacity] = useState<number>(0);

  useEffect(() => {
    if (isZoomed) {
      setTransform(getTransform());
      setTimeout(() => {
        setDisplay("visible");
        setOpacity(1);

        setTimeout(() => {
          setTransform(undefined);
        });
      });
    } else {
      setTransform(getTransform());
      setTimeout(() => {
        setDisplay("hidden");
        setOpacity(0);
      }, 200);
    }
  }, [getTransform, isZoomed]);

  const { className, ...rest } = props;

  const src =
    "fluid" in props
      ? (props.fluid as FluidObject | undefined)?.src
      : undefined;

  const portal =
    typeof document !== "undefined"
      ? createPortal(
          <Zoomed style={{ visibility }}>
            <Background style={{ opacity }} />
            <Body ref={container}>
              <img
                onLoad={() => calculatePreviewRect()}
                ref={preview as any}
                style={{ transform }}
                src={src}
                alt={props.alt}
                onClick={() => setIsZoomed(x => !x)}
              />
              {description ? <Description>{description}</Description> : null}
            </Body>
          </Zoomed>,
          document.body
        )
      : null;

  return (
    <>
      <div
        className={className as string}
        onClick={() => {
          setIsZoomed(x => !x);
        }}
      >
        <GatsbyImage ref={original as any} {...rest} />
      </div>
      {portal}
    </>
  );
})`
  cursor: zoom-in;
`;

export default ZoomableImage;
