import styled from "styled-components";
import { onDesktop } from "../../shared/layout/Breakpoints";

const Quote = styled.blockquote`
  margin-top: 1em;
  font-style: italic;
  display: flex;
  margin-left: 0;
  margin-right: 0;

  &:before {
    display: block;
    content: "”";
    font-family: Times, Times New Roman, Georgia, serif;
    font-style: italic;
    font-size: 2em;
    margin-right: 0.25em;
    line-height: 1em;
  }

  ${onDesktop`
    margin-right: 1.45rem;

    &:before {
      font-size: 4em;
    }
  `}
`;

export default Quote;
